import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./index.scss";
import FluidContent from "../components/fluidContent";
import Button from "../components/Button";
import Banner from "../components/Banner";
import BlockTitle from "../components/BlockTitle";
import { Col, Row } from "react-grid-system";
import CardInfo from "../components/cards/cardInfo";
import { Link } from "gatsby";
import ListBg1 from "../images/about-item1-bg.webp";
import ListBg2 from "../images/bg-water-skyblue.webp";
import ListBg3 from "../images/bg-water-orange.webp";
import ListBg4 from "../images/bg-water-softblue.webp";
import ListBg5 from "../images/bg-water-softpink.webp";
import ListBg6 from "../images/bg-water-yellow.webp";
import omegafundsLogo from "../images/investors-logo-omegafunds.webp";
import radforskLogo from "../images/investors-logo-radforsk.webp";
import thirdRockLogo from "../images/investors-logo-thirdrock.webp";
import aboutBgMobile from "../images/about-bg-mobile.webp";
import TeamDetails from "../components/teamDetails";
import royLarsenImg from "../images/roy-larsen.webp";
import ovyindBrulandImg from "../images/ovyind-bruland.webp";
import emanueleImg from "../images/emanuele-ostuni.webp";
import nickImg from "../images/nick-pullen.webp";
import conradImg from "../images/conrad-wuller.webp";
//import fogPipeline from "../images/pipeline-fog.png";
import parabilisPipeline from "../images/pipeline-parabilis.webp";
import calvenImg from "../images/calvin-lee.webp";
import vickiImg from "../images/vicki-jardine.webp";
import margaretImg from "../images/margaret-yu.webp";
import lilyImg from "../images/lily-bouzelmat.webp";
import victorImg from "../images/victor-paulus.webp";
import danielImg from "../images/daniel-r.png";
import gjermundImg from "../images/gjermund-olsen.webp";
import tanjaImg from "../images/tanja-aarvak.webp";
import bharatImg from "../images/bharat-lagu.webp";
//import adaImg from "../images/ada-repetto.webp";
import philImg from "../images/phil-ruis.webp";
import vyasImg from "../images/vyas-ramanan.webp";
import kateImg from "../images/kate-devlin.webp";
import oliverImg from "../images/oliver-sartor.webp";
import richardImg from "../images/richard-baum.webp";
import ivanaImg from "../images/ivana-galetic.webp";
import dennisImg from "../images/dennis-benjamin.webp";
import tedImg from "../images/ted-love.webp";
import susannelImg from "../images/susanne-schaffert.webp";
import alexImg from "../images/alex-pasteur.webp";
import otelloImg from "../images/otello-stampacchia.webp";
import andrewImg from "../images/anders-tuv.webp";
import martinImg from "../images/martin-taylor.webp";
import sanjayImg from "../images/sanjay-sharma.png";
import jordiFerranImg from "../images/jordi-vall-llosera-ferran.webp";
import francessoImg from "../images/francesco-draetta.webp";
import amraImg from "../images/amra-demirovic.webp";
import fPrimeLogo from "../images/investors-logo-fprime.webp";
import agileraLogo from "../images/partner-logo-agilera.webp";
import alphaGenLogo from "../images/partner-logo-alphagen.webp";
//import eckertLogo from "../images/partner-logo-eckertandziegler.webp";
import nucleusLogo from "../images/logo-nucleus-radiopharma-retina.png";
//import fogLogo from "../images/partner-logo-fogpharma.webp";
import parabilismedicines from "../images/partner-logo-parabilismedicines.webp";
import thormedical from "../images/partner-logo-thormedical.webp";
import OsloLogo from "../images/partner-logo-university-of-oslo.webp";
import threeBLogo from "../images/3b-pharmaceuticals.webp";
import pharmaLogicLogo from "../images/partner-logo-pharmalogic.webp";
import spectronRxLogo from "../images/partner-logo-spectronrx.webp";
import newsBgDesktop from "../images/news-bg-desktop.webp";
import newsBgMobile from "../images/news-bg-mobile.webp";
import philippeDasseImg from "../images/philippe-dasse.webp";
import jeffreyTongImg from "../images/jeffrey-tong.webp";
import nicoleImg from "../images/nicole-hadas.webp";
import ranjeevKrishanaImg from "../images/ranjeev-krishana.webp";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { navigate } from "gatsby";
import ResponsiveTabs, {
  TabList,
  TabPanel,
  Tab,
} from "../components/responsive-tabs";
import AllBlogPosts from "../components/allBlogPosts";
import Section from "../components/section";
import MyLink from "../components/MyLink";
import axios from "axios";
import CardJob from "../components/cards/cardJob";
import PlusIcon from "../images/plus-icon.svg";
import threeBPharmaceuticals from "../images/partner-logo-3b-pharmaceuticals.webp";

const initailJobToShow = 3;

const API_URL = "/.netlify/functions/open-positions";

const IndexPage = () => {
  const [openPositions, setOpenPositions] = React.useState([]);

  const [jobToShow, setJobToShow] = React.useState(initailJobToShow);

  const handleLoadMoreClick = () => {
    setJobToShow(jobToShow + initailJobToShow);
  };

  React.useEffect(() => {
    async function getOpenPositions() {
      await axios
        .get(API_URL)
        .then((res) => {
          const allJobs = res.data?.data?.data ?? [];

          const activeJobs = allJobs.filter(
            (item) => item.state === "published"
          );
          setOpenPositions(activeJobs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getOpenPositions();
  }, []);

  const fadeInPlaceRef = React.useRef([]);
  fadeInPlaceRef.current = [];

  const fadeInPlaceRefs = (fade) => {
    if (fade) {
      fadeInPlaceRef.current.push(fade);
    }
  };

  const fadeRef = React.useRef([]);
  fadeRef.current = [];

  const fadeToRefs = (fade) => {
    if (fade) {
      fadeRef.current.push(fade);
    }
  };
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let mm = gsap.matchMedia();
    // mm.add("(min-width: 375px)", () => {
    //   gsap.from(".table-progress", {
    //     width: "17px",
    //     duration: 1,
    //     stagger: 0.5,
    //     scrollTrigger: {
    //       trigger: ".pipeline-block",
    //       start: "bottom 115%",
    //     },
    //   });
    // });

    mm.add("(min-width: 375px) and (max-width: 991px)", () => {
      gsap.from(".table-progress", {
        width: "17px",
        duration: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".pipeline-block",
          start: "bottom 240%",
        },
      });
    });

    mm.add("(min-width: 991px)", () => {
      gsap.from(".table-progress", {
        width: "17px",
        duration: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".pipeline-block",
          start: "bottom 115%",
        },
      });
    });

    mm.add("(max-width: 374.98px)", () => {
      let tl = gsap.from(".table-progress", {
        duration: 1,
        stagger: 0.5,
        repeatRefresh: true,
        repeat: 0,
      });
      tl.progress(0);
      tl.invalidate();
      tl.play();
    });

    fadeRef.current.forEach((fade) => {
      gsap.set(fade, {
        opacity: 0,
        y: 100,
      });

      gsap.to(fade, {
        opacity: 1,
        y: 0,
        duration: 1.5,
        delay: 0.5,
        scrollTrigger: {
          trigger: fade,
        },
      });
    });

    fadeInPlaceRef.current.forEach((fade) => {
      gsap.set(fade, {
        opacity: 0,
      });

      gsap.to(fade, {
        opacity: 1,
        duration: 1.5,
        delay: 2.2,
        scrollTrigger: {
          trigger: fade,
        },
      });
    });
  }, []);
  return (
    <HcpLayout pageClass="homepage">
      <Section id="banner">
        <div className="hero-wrapper">
          <Banner />
        </div>
      </Section>
      <Section id="about">
        <FluidContent className="about-block bg-cover block-inner-py">
          {/* <img src={aboutBgDesktop} alt="About" className="desktop-only" /> */}
          <div className="centered-content">
            <Row>
              <Col lg={5}></Col>
              <Col lg={7}>
                <div className="about-content">
                  <div ref={fadeToRefs}>
                    <BlockTitle
                      className="gradient-border"
                      Title="The ARTBIO Approach: Patients at the Core"
                    />
                    <p>
                      Radioligand therapy (RLT) has offered a promising new
                      approach for cancer treatment. While beta radioligand
                      therapies are currently more common in clinical practice,
                      alpha radioligand therapies offer potentially
                      significantly better efficacy and safety to patients.
                    </p>
                    <p>
                      The ARTBIO approach is holistic. It is underpinned by a
                      deep understanding of cancer biology, and the
                      infrastructure needed to effectively design, manufacture,
                      and distribute our therapies.
                    </p>
                    <p>Our patient-centric approach rests on four pillars:</p>
                  </div>
                  <div className="about-list mt-20" ref={fadeToRefs}>
                    <Row>
                      <Col lg={6} className="mb-20">
                        <MyLink to="/our-approach#accordion-wrap">
                          <CardInfo
                            className="bradius-topleft-40"
                            cardBg={ListBg1}
                            cardBgAlt="bg-soft-red shape"
                            cardTitle="Differentiated pipeline"
                          />
                        </MyLink>
                      </Col>
                      <Col lg={6} className="mb-20">
                        <MyLink to="/our-approach#accordion-wrap">
                          <CardInfo
                            className="bradius-topright-40"
                            cardBg={ListBg2}
                            cardBgAlt="bg-sky-blue shape"
                            cardSupTitle={`<span> Isotope production: <i>AlphaDirect<sup>TM</sup></i></span>`}
                          />
                        </MyLink>
                      </Col>
                      <Col lg={6} className="mb-20">
                        <MyLink to="/our-approach#accordion-wrap">
                          <CardInfo
                            className="bradius-bottomleft-40"
                            cardBg={ListBg3}
                            cardBgAlt="bg-orange shape"
                            cardTitle="Ideal isotope: Pb212"
                          />
                        </MyLink>
                      </Col>
                      <Col lg={6} className="mb-20">
                        <MyLink to="/our-approach#accordion-wrap">
                          <CardInfo
                            className="bradius-bottomright-40"
                            cardBg={ListBg4}
                            cardBgAlt="bg-voilet shape"
                            cardTitle="ART manufacturing ecosystem"
                          />
                        </MyLink>
                      </Col>
                    </Row>
                    <div className="btn-wrap">
                      <Button
                        btnTitle="Learn more"
                        onClick={() => navigate("/our-approach")}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="about__mobileBg mobile-only">
            <img
              src={aboutBgMobile}
              alt="About bg"
              loading="lazy"
              width={375}
              height={551}
            />
          </div>
        </FluidContent>
      </Section>

      <Section id="pipeline">
        <FluidContent className="pipeline-block block-inner-py">
          <div className="centered-content">
            <div className="pipeline-block__content" ref={fadeToRefs}>
              <BlockTitle
                className="gradient-border"
                Title="A Differentiated Pipeline"
              />
              <p>
                We are identifying specific combinations of targets and drug
                molecules best suited for ART. Our ARTs have high affinity and
                selectivity in order to effectively kill tumors while sparing
                healthy tissues.
              </p>
            </div>
            <h3 className="sub-title">
              Currently, ARTBIO has 10+ pipeline programs with many more in
              development:
              <div className="dot-list">
                <ul>
                  <li>
                    Our lead program AB001 has entered first in human studies.
                  </li>
                  <li>
                    Three additional targets (ABA, ABB, and ABC) remain
                    undisclosed.
                  </li>
                </ul>
              </div>
            </h3>
            <div className="pipeline-bar">
              <div className="table-block">
                <div className="desktop-only">
                  <Row className="table-row table-head">
                    <Col className="table-col" lg={2} xs={6}>
                      <span className="h4">Programs</span>
                    </Col>
                    <Col className="table-col mobile-none" lg={2}>
                      <span className="h4">Indication</span>
                    </Col>
                    <Col className="table-col mobile-none" lg={2}>
                      <span className="h4">Discovery</span>
                    </Col>
                    <Col className="table-col mobile-none" lg={2}>
                      <span className="h4">Lead Optimization</span>
                    </Col>
                    <Col className="table-col table-col--right" lg={2} xs={6}>
                      <span className="h4">Phase 0</span>
                    </Col>
                    <Col className="table-col table-col--right" lg={2} xs={6}>
                      <span className="h4">Clinical Stage (Phase 1-3)</span>
                    </Col>
                  </Row>
                </div>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>AB001</h6>
                      <span>
                        <sup>212</sup>Pb-NG001
                      </span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Prostate Cancer</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div className="table-progress table-progress--full"></div>
                    <h6 className="mobile-only">Phase 0</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img" lg={2}></Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>ABD</h6>
                      <span>
                      Fully-Owned, In-License
                      </span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div className="table-progress table-progress--50"></div>
                    <h6 className="mobile-only">Lead Optimization</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img pl-lg-0 has-partnerLogo" lg={3.5} xl={3} ref={fadeInPlaceRefs}>
                    {" "}
                    <img
                      src={threeBPharmaceuticals}
                      alt="3B Pharmaceuticals logo"
                      width={450}
                      height={79}
                      className="max-w-225"
                      loading="lazy"
                    />
                  </Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>ABA</h6>
                      <span>
                        <sup>212</sup>Pb-undisclosed
                      </span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div className="table-progress table-progress--50"></div>
                    <h6 className="mobile-only">Lead Optimization</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img" lg={2}></Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>ABB</h6>
                      <span>
                        <sup>212</sup>Pb-undisclosed
                      </span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div className="table-progress table-progress--50"></div>
                    <h6 className="mobile-only">Lead Optimization</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img" lg={2}></Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>ABC</h6>
                      <span>
                        <sup>212</sup>Pb-undisclosed
                      </span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div className="table-progress table-progress--40"></div>
                    <h6 className="mobile-only">Discovery</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img" lg={2}></Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div >
                      <h6>Parabilis Medicines</h6>
                      <span>Multiple Targets</span>
                    </div>
                  </Col>

                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>

                  <Col className="table-col table-bar" lg={1.35} xl={1.15}>
                    <div
                      className="table-progress table-progress--20"
                    // style={{ width: "100px" }}
                    ></div>
                    <h6 className="mobile-only">Discovery</h6>
                  </Col>

                  <Col
                    className="table-col table-col__img has-partnerLogo"
                    ref={fadeInPlaceRefs}
                    lg={2}
                  >
                    {" "}
                    <img
                      src={parabilisPipeline}
                      width={500}
                      height={150}
                      alt="Parabilis medicines logo"
                      loading="lazy"
                      className="max-w-112"
                    />
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                </Row>

                <Row className="table-row">
                  <Col className="table-col table-col__title" lg={2}>
                    <div>
                      <h6>Multiple Targets</h6>
                      <span>In Screening</span>
                    </div>
                  </Col>
                  <Col className="table-col table-col__title" lg={2}>
                    <span>Multiple Solid Tumors</span>
                  </Col>
                  <Col className="table-col table-bar" lg={2}>
                    <div
                      className="table-progress table-progress--20"
                    // style={{ width: "100px" }}
                    ></div>
                    <h6 className="mobile-only">Discovery</h6>
                  </Col>
                  <Col className="table-col mobile-none" lg={2}></Col>
                  <Col className="table-col table-col__img" lg={2}></Col>
                </Row>
              </div>
            </div>
          </div>
        </FluidContent>
      </Section>

      <Section id="team">
        <FluidContent className="team-block block-inner-py">
          <div className="centered-content">
            <div className="team-block__content text-white" ref={fadeToRefs}>
              <BlockTitle
                className="text-white gradient-border gradient-border--yellow"
                Title="A Team of Pioneers and Innovators in RLT"
              />
              <p>
                ARTBIO is shaped by a long-standing scientific legacy with
                nearly a century of pioneering work in radiation therapy. Born
                out of groundbreaking research conducted at the University of
                Oslo and Norway’s Radium Hospital - a research hub with lineage
                to Marie Curie - ARTBIO’s roots in radiology and radiation
                therapy run deep. Our scientific founders, Roy Larsen and Øyvind
                Bruland, invented Xofigo (later acquired by Bayer via Algeta),
                the first-ever metabolically targeted alpha therapy. Both
                collaborated with the F-Prime team and Radforsk to lay the
                foundation of ARTBIO in 2021.
              </p>
              <p>
                Our team is composed of multi-disciplinary experts with decades
                of experience in advancing complex drugs through all stages of
                clinical development. We are driven by the power of collective
                thinking, diversity, and a passion to create smarter solutions
                together. Finally, we take pride in combining creativity and
                scientific expertise to address challenges. We value inclusion,
                collaboration, and fostering strong relationships with our team,
                partners, health care.
              </p>
            </div>
            <div className="tab-block">
              <ResponsiveTabs>
                <TabList>
                  <Tab>Management Team</Tab>
                  <Tab>Scientific Founders</Tab>
                  <Tab>Scientific Advisory Board</Tab>
                  <Tab>Board of Directors</Tab>
                </TabList>

                <TabPanel>
                  <Row>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Emanuele Ostuni, PhD"
                        designation="Founding CEO"
                        imgRef={emanueleImg}
                        description="<p>Emanuele is the Founding CEO of ARTBIO. He worked closely with Roy Larsen and ARTBIO’s investors to build the company and its business model. Previously, he was Head of Europe for Cell and Gene Therapies at Novartis Oncology, where he oversaw all commercialization aspects for Kymriah in Europe, the first FDA-approved CAR-T cell therapy. Emanuele was with Novartis since 2012 in various executive roles in Sandoz with different geographic responsibilities: Global, Central and Eastern Europe, and Romania. Previously he held positions at Nano Terra, McKinsey & Co., and Surface Logix. Emanuele was the Co&#x2060;Founder of Enumeral Biomedical and served on its board of directors through its public listing in 2014. He served on the Scientific Advisory Board (SAB) of Anixa Biosciences and currently serves on the SAB of Elicio Therapeutics. Emanuele earned a PhD in physical chemistry from Harvard University and bachelor's and master's degrees in chemistry from Georgetown University.</p><p>Artist or Artisan?</p><p>Artisan. Emanuele is passionate about skiing, gardening, and growing orchids.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Nick Pullen, PhD"
                        designation="Chief Scientific Officer"
                        imgRef={nickImg}
                        description="<p>Nick is Chief Scientific Officer at ARTBIO. Nick has over 20 years of experience leading drug discovery and development programs across multiple gene classes, modalities and indications. Before joining ARTBIO, he was Senior Vice President and Head of Research at Jnana Therapeutics, where he helped support multiple fundraising rounds, completed a multi-target partnership with Roche, and built a pipeline of novel immunology and oncology programs. Prior to working at Jnana, Nick was Vice President of Integrative Sciences at Celgene/BMS, and before that, he worked at Pfizer in several scientific leadership positions. Nick is accredited with more than 50 peer-reviewed publications, patents, and book chapters. He received his Ph.D. in biochemistry from the University of Southampton in the UK.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Margaret Yu, MD"
                        designation="Chief Medical Officer"
                        imgRef={margaretImg}
                        description="<p> Margaret is ARTBIO’s Chief Medical Officer and is responsible for spearheading clinical development and regulatory strategies. With over 15 years of oncology drug development experience, Margaret has held key leadership roles at Johnson & Johnson and Janssen, where she led clinical programs for ZYTIGA and ERLEADA, achieving regulatory approval for ERLEADA in non-metastatic castration-resistant prostate cancer. Margaret also contributed to niraparib's clinical strategy and advanced biologics in late-stage development. Her career began as Director of Clinical Research at Myriad Pharmaceuticals, later transitioning to oncology roles at Janssen. A medical oncologist, Margaret completed her Internal Medicine residency and Medical Oncology and Hematology fellowship at the University of Utah and is an active member of the American Society of Clinical Oncology.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Philippe Dasse, PharmD"
                        designation="Chief Technical Officer"
                        imgRef={philippeDasseImg}
                        description="<p> Philippe is Chief Technical Officer of ARTBIO. He has more than 20 years of experience in building innovative radiopharmaceutical operations in fast-growing environments. Prior to ARTBIO, Philippe was the Head of Technical Operations and a member of the Executive Leadership Team at Advanced Accelerator Applications, before and after its acquisition by Novartis. He has led different pharmaceutical and business functions in the radiopharmaceutical ecosystem from biotech to big pharma. He is also an advisor to promising startups in Medtech. In addition to ARTBIO, Philippe currently serves on the board of Predisurge. Philippe holds a PharmD from Institut national des sciences et techniques nucléaires and Tours University, and an executive MBA from the International Institute for Management Development.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Amra Demirovic"
                        designation="Chief Human Resources Officer"
                        imgRef={amraImg}
                        description="<p> Amra is ARTBIO’s Chief Human Resources Officer. In this role, Amra is responsible for leading the alignment of human resources (HR) initiatives with the company's mission and values. With a wealth of experience in HR and a strong record of cultivating inclusive workplace cultures, Amra is dedicated to driving positive organizational change. Before joining ARTBIO, she oversaw HR for Europe & New Markets at BeiGene and held key HR positions at Novartis. Amra holds a Master’s degree in Industrial and Organizational Psychology from the University of Basel.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Nicole Hadas"
                        designation="Chief Legal Officer"
                        imgRef={nicoleImg}
                        description="<p> Nikki is ARTBIO’s Chief Legal Officer and oversees the company’s legal and regulatory strategy. With extensive experience in the life sciences sector, Nikki most recently served as Senior Vice President and Chief Legal Officer at Akebia Therapeutics, where she guided the company through its transition from a private, clinical-stage entity to a publicly traded company with two commercial products. Prior to that, Nikki was Senior Vice President and General Counsel at Inspiration Biopharmaceuticals, where she played a key role in the successful sale of the company’s hemophilia assets. Nikki began her in-house legal career in life sciences at Genzyme Corporation where she supported various business units during her ten years with the company. Prior to Genzyme she was an associate at Foley Hoag, representing biopharmaceutical companies and healthcare providers in a wide range of legal matters. She received a B.A. from the University of Michigan and a J.D. from Boston College Law School.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Conrad Wüller, MSc"
                        designation="Senior Director, Strategy and Operations"
                        imgRef={conradImg}
                        description="<p> Conrad is ARTBIO’s Senior Director of Strategy and Operations, working closely with Emanuele and the Board of Directors. Before joining ARTBIO, he was Head of Medical Strategy at AAA/Novartis for the radioligand therapy portfolio, which includes PLUVICTO and LUTATHERA. Previously he held various roles at McKinsey & Co, working with life sciences and public health organizations in solving complex strategic and operational challenges. Conrad holds a master’s double degree from the Stockholm School of Economics and the CEMS Global Management Alliance, and a bachelor’s from the University of Mannheim. He is an avid outdoor and language enthusiast</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Calvin Lee, MBA"
                        designation="Vice President, Finance"
                        imgRef={calvenImg}
                        description="<p> Calvin is ARTBIO’s Vice President of Finance and closely collaborates with the CEO, the Board of Directors, and investors. Prior to joining ARTBIO, Calvin progressed through various roles across multiple geographies as part of the CFO program at Novartis and was the European Head of Business Planning & Analysis and Head of Finance at Novartis Oncology. Previously, he gained experience in international corporate tax planning, audit, forensic accounting, and business valuations at a global accounting firm. Calvin holds an MBA from IESE Business School and a Bachelor of Commerce from Rotman School of Management at University of Toronto. He is professionally qualified as a CPA-CA (Canada), CPA (Australia), CMA, CBV and CFE. He is all about traveling. </p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Vicki Jardine, MD, PhD"
                        designation="Vice President, Clinical Development"
                        imgRef={vickiImg}
                        description="<p>Vicki is the Vice President of Clinical Development at ARTBIO, working closely with Lily and Victor on the clinical programs, and Ada and Phil on translation and pipeline development. Before joining ARTBIO, she was Senior Clinical Development Leader at Bayer for the Targeted Alpha Therapy platform, responsible for early clinical development of several Actinium-225 and Thorium-227 based agents. She has held various positions in oncology clinical development and project management at Bayer covering early to late-stage clinical research. Vicki gained her Medical Doctorate, PhD in radiology, and bachelor’s degree in neuroscience from the University of Cambridge. She is passionate about independent travel and music.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Lily Bouzelmat, MSc "
                        designation="Vice President, Development Operations"
                        imgRef={lilyImg}
                        description="<p>Lily is ARTBIO’s Vice President of Development Operations, working closely with the clinical, preclinical, and manufacturing teams. Before joining ARTBIO, she was Chief Development Officer at SolasCure, leading the development team for a complex advanced wound care asset. Lily brings more than 12 years’ experience, having led several clinical studies ranging through phase 1 to 3 in various therapeutic areas including immune-oncology and rare diseases at both Regeneron and Merrimack Pharmaceuticals in the US. Lily holds a master’s degree in biotechnology from McGill University and a master’s degree in molecular and cell biology from Sorbonne Université (Pierre et Marie Curie).</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Jordi Vall-llosera Ferran, MSc"
                        designation="Senior Vice President and Global Head of Quality "
                        imgRef={jordiFerranImg}
                        description="<p> Jordi is Senior Vice President and Head of Quality at ARTBIO. Jordi’s career reflects an established record of leadership in quality and supply chain processes by working closely with executive teams to reach companies’ goals. Prior to joining ARTBIO, Jordi was Head of Quality Assurance for Advanced Accelerator Applications (AAA), a Novartis company, overseeing and leading all Quality strategy and operational activities, ranging from development and clinical activities, manufacturing, testing and release activities, among other responsibilities. Prior to joining AAA in 2018, he served in several roles at Novartis, including Global Head of Supply Chain Quality Assurance, Head of Pharma Supply Chain Quality Assurance, Global Quality Process Lead within the Novartis Pharma Quality organization, and Global Quality Operations Manager for Products. He spent the first five years of his professional career as a Quality Management and Business Consultant specialized in operations and information technology at Accenture (Andersen Consulting). Jordi holds a degree in Technical Agricultural Engineering from the Polytechnic University of Catalonia and a degree in Agronomical Engineering from the University of Lleida. He has also completed a special Quality Management Program at the Escuela Organizacion Industrial in Barcelona, Spain.</p>"
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Victor Paulus, PhD"
                        designation="Head and Senior Vice President, Regulatory Affairs"
                        imgRef={victorImg}
                        description={`<p>Victor has more than 35 years of experience in the pharmaceutical industry and more than 20 in regulatory affairs. Before joining ARTBIO, Victor served as Senior Vice President of Regulatory Affairs at Fusion Pharmaceuticals. Immediately prior to Fusion, he was Senior Vice President of Regulatory Affairs at Abeona Therapeutics. Victor also was Global Head of Regulatory Affairs for the Geneva-based Advanced Accelerator Applications (AAA), a Novartis Company. While at AAA, Victor achieved two product approvals (gallium Ga68 dotatate and lutetium Lu177 dotatate) for the diagnosis and treatment of gastroenteropancreatic neuroendocrine tumors (GEP-NETs).</p>

                        <p>During his career, he has served as Director of Regulatory Affairs for Pediatric Vaccines at GlaxoSmithKline and senior director of regulatory affairs for biosimilar drug development at Dr. Reddy’s. Victor has bachelor’s degrees in biology and biochemistry, a master’s in biology (parasitology), and a doctorate in public health. Victor also served on the Board of Directors of Evergreen Theragnostics.</p>`}
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Daniel Rossetto, MSc"
                        designation="Head & SVP, Supply Chain and External Manufacturing"
                        imgRef={danielImg}
                        description={`<p>Daniel joins ARTBIO after more than 15 years in the pharmaceutical business in Technical Operations, Supply Chain and Quality. Daniel lastly held the position of Head of Supply Chain and Isotopes Manufacturing for Novartis Radioligand Therapy Operations / Advanced Accelerator Applications, a Novartis company, with responsibility to oversee end-to-end supply chain of diagnostic and therapeutic products for both commercial and clinical use. Prior to joining Advanced Accelerator Applications, Daniel served in several leadership roles in Global Supply Chain and in manufacturing sites of Pharma, Sandoz, and Vaccines. Daniel holds a degree in Logistics and Production Engineering and completed a Master’s degree in Business Management and Industrial Engineering at the Politecnico di Torino, Italy. Daniel is passionate about outdoor sports such as running, cycling, skiing with his family, and he enjoys gardening.</p>`}
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Tanja Aarvak, PhD"
                        designation="Director, Drug Product Development and Manufacturing"
                        imgRef={tanjaImg}
                        description="<p>Tanja is ARTBIO’s Director of Drug Product Development and Manufacturing, working with generator design and manufacturing network. Before joining ARTBIO, she was Director of Program Management at Nordic Nanovector, leading targeted radionuclide therapies development programs. Previously she held research and development leadership roles in Thermofisher Scientific working with product development for CAR-T cell immunotherapies. Tanja holds a master’s degree in biotechnology from the Norwegian University of Science and Technology and a PhD in immunology from the University of Oslo. She is a lover of nature and outdoor sports.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Gjermund Fjeld Olsen, MSc "
                        designation="Director, Generator Development and Manufacturing "
                        imgRef={gjermundImg}
                        description="<p>Gjermund is ARTBIO’s Director of Generator Development and Manufacturing, working closely with Tanja to develop the generator and build the manufacturing network. Before joining ARTBIO, he was Head of Technology Development in Product Supply at Bayer (Oslo), which includes Xofigo and the TRT development portfolio. Previously he held various roles at Nycomed Imaging, Amersham Health, GE Healthcare, NeoRad, and Algeta, working with various manufacturing science and  technology aspects. Gjermund holds a BSc in mechanical engineering and an MSc in pharmaceutical engineering from University of Manchester. He is an outdoor person who enjoys everything related to cars.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Bharat Lagu, PhD"
                        designation="Senior Vice President, Discovery"
                        imgRef={bharatImg}
                        description="<p>Bharat brings to ARTBIO a track record of delivering nine clinical candidates across multiple therapeutic areas in pharmaceutical and biotechnology companies as a medicinal chemist. Before joining ARTBIO, Bharat was Vice President for Chemistry at Mitobridge (acquired by Astellas Pharma in 2018), where he contributed to the discovery of clinical candidates that impacted mitochondrial functions. Prior to that he was at the Novartis Institutes for Biomedical Research, where he contributed to the discovery of several clinical candidates including Kisqali® (a blockbuster CDK4/CDK6 inhibitor for metastatic breast cancer). Bharat also had successful stints at Johnson & Johnson Pharmaceutical R&D and Synaptic Pharmaceutical Corp. He has extensive experience working on medicinal chemistry and CMC programs using the global network of CROs and CMOs. Bharat is a co-&#x2060;author on 45 papers and a co-⁠inventor on more than 50 patents/patent applications. He received his PhD in chemistry from Clemson University and did post-doctoral research at Emory University. Bharat enjoys playing percussion instruments and watching sports in his spare time.</p>"
                      />
                    </Col>
                    {/* <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Ada Repetto-Llamares, PhD"
                        designation="Director, Translational Biology"
                        imgRef={adaImg}
                        description="<p>Ada is ARTBIO’s Director of Translational Biology, working closely with the discovery, clinical, and business development teams. She brings in 15 years of experience within the radiopharmaceutical space. Before joining ARTBIO, she held various roles at Nordic Nanovector, leading novel radioligand therapeutics from discovery into IND-readiness and clinical phase. Previously, she worked at the National Commission of Atomic Energy Argentina in the development of innovative brachytherapy treatments. Ada holds a PhD in radioligand therapy from the University of Oslo, a PhD in Engineering from the Norwegian University of Science and Technology, and a master’s degree in nuclear physics from the Balseiro Institute. She is a nature & skiing enthusiast.</p>"
                      />
                    </Col> */}
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Phil Ruis, PhD"
                        designation="Director, Partnered Discovery and Business Development"
                        imgRef={philImg}
                        description="<p>Phil is the Director of Partnered Discovery and Business Development at ARTBIO, connecting the technical and strategic parts of the company and working closely with external partners. Before joining ARTBIO, he held a diverse role as Co-&#x2060;Founder and Head of Biology at a stealth-mode precision oncology start-up, leading target discovery and validation work plus shaping company strategy. Previously, he spent time in academia, highlighted by the publication of multiple Nature papers in the fields of cell biology and genome stability, and consulting for early-stage life science start-ups. Phil holds a PhD from The Francis Crick Institute and University College London in cell and molecular biology plus an MSci/BA in natural sciences (biochemistry) from Cambridge University, where he graduated first in his class.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Kate Devlin Rushton"
                        designation="Vice President, Human Resources"
                        imgRef={kateImg}
                        description="<p>Kate is ARTBIO's Vice President of Human Resources and is responsible for leading the company's talent, culture and engagement practices. Kate has nearly two decades of experience leading talent acquisition, human resources (HR) initiatives and employee relations. Prior to joining ARTBIO, Kate operated her own HR and People consulting firm and also held HR leadership positions at several biopharmaceutical companies, including GentiBio, Rubius Therapeutics and Biogen. With a strong background in employee relations, recruitment and retention, Kate brings a wealth of experience in building teams to drive organizational success in the biotech industry. Kate has degrees from both University of Rhode Island and Universidad Pablo de Olavide.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Ivana Galetic, PhD"
                        designation="Senior Vice President, Program Management"
                        imgRef={ivanaImg}
                        description="<p>Ivana is ARTBIO's Senior Vice President of Program Management and is responsible for overseeing program management strategy, portfolio governance, and integrated program strategies. Ivana brings over 20 years of experience covering all aspects of drug development from discovery to commercialization in small and big companies. Prior to joining ARTBIO, Ivana led the radiopharmaceutical franchise at Debiopharm and headed program leadership and program management functions at Debiopharm and Advanced Accelerator Applications (AAA), a Novartis company. Her responsibilities spanned portfolio governance, strategy and execution across entire R&D. Ivana's career began at Amgen/Biomit, followed by various roles at Roche and Novartis, contributing to the development of numerous successful drugs, including Aranesp, Vectibix, Kymriah, Lutathera and Pluvicto, among many others. She holds a PhD in Biochemistry from the University of Basel, along with MSc degree in Molecular Biology from the University of Zagreb and MSc in Pharmaceutical Medicine from the University of Surrey.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Sanjay Sharma, MSc, CRadPhr, PhD"
                        designation="Vice President, CMC Operations and Development"
                        imgRef={sanjayImg}
                        description="<p>Sanjay is the Vice President of CMC Operations and Development at ARTBIO, bringing over 15 years of experience in radiopharmaceutical CMC development, isotope manufacturing, and regulatory submissions. Before joining ARTBIO, he was Director of CMC at Alpha 9 Oncology, managing CDMOs and internal teams for targeted radiotherapy. Sanjay has led GMP manufacturing and CMC/GMP activities for clinical trial products across global markets. Earlier he was Director with Alberta Health Services (Edmonton Radiopharmaceutical Centre/Medical Isotope and Cyclotron Facility) managing the development, production and supply of radiopharmaceuticals and cold kits. He began his career as a medicinal chemist at NAEJA Pharmaceuticals and holds advanced degrees in Medicinal Chemistry and Chemistry, including a PhD from the University of Delhi and Post-doctoral studies from University of Alberta, as well as an Advanced PG Certificate in Radiopharmaceutical Chemistry from ETH Zurich. He is the recipient of a Young Scientist Award from the Indian Council of Chemists and a three-time winner of the Bridge and Pilot award from the Alberta Cancer Foundation. Sanjay is a co-author in 28 peer reviewed research papers and inventor in four patents. In his free time Sanjay loves to watch cricket and ice hockey and read books.</p>"
                      />
                    </Col>
                  </Row>
                </TabPanel>

                <TabPanel>
                  <Row>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Roy H. Larsen, PhD"
                        designation="Scientific Co-&#x2060;Founder"
                        imgRef={royLarsenImg}
                        description="<p>Dr. Roy H. Larsen is the Co-Founder of ARTBIO and he works as a consultant through Sciencons AS. Dr. Larsen is an experienced inventor with a track record of innovation in radiopharmaceuticals. He founded Algeta ASA in 1997 to develop the first and only therapeutic containing an alpha emitter which became Xofigo. He served in different roles as the initial Managing Director, Chief Scientific Officer, while also serving on the Board of Directors. Algeta was acquired by Bayer in 2014. Dr. Larsen is also one of the founders of Nordic Nanovector ASA (2009), and Oncoinvent AS (2010). Roy H. Larsen was chairman of the board in Nordic Nanovector from 2009 to 2014, and a member of the Board until 2016. Dr. Larsen is currently Chairman of Oncoinvent. Dr. Larsen holds a PhD in radiopharmaceutical chemistry from University of Oslo, Norway, and had postdoctoral experience at Duke University, USA.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Øyvind Bruland, MD, PhD"
                        designation="Scientific Co-&#x2060;Founder"
                        imgRef={ovyindBrulandImg}
                        description="<p>Dr. Bruland is a Professor of Clinical Oncology and Faculty of Medicine at the University of Oslo. His research includes primary bone and soft-tissue cancers (sarcomas) and skeletal metastases from prostate cancer and breast cancer; targeted radionuclide therapy, such as the clinical development of Alpharadin, based on Radium-223; the tumor biology and prognostic impact of micro-metastases in bone-marrow aspirates on patients with primary bone cancer (osteosarcoma); external beam radiotherapy; and the radiotherapy of skeletal metastases and soft-tissue sarcomas. Dr. Bruland also is Co-⁠&#x2060;Founder of numerous biotech companies, including Algeta AS, Nordic Nanovector AS, Oncoinvent AS, and Nucligen/ARTBIO, Inc. He was elected a member of the Norwegian Academy of Science and Letters in 2008. Dr. Bruland holds a BSc, MD, and PhD from the University of Oslo, Norway.</p>"
                      />
                    </Col>
                  </Row>
                </TabPanel>

                <TabPanel>
                  <Row>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName=" Øyvind Bruland, MD, PhD"
                        designation="SAB Chairman and ARTBIO Co-&#x2060;Founder"
                        imgRef={ovyindBrulandImg}
                        description="<p>Dr. Bruland is a Professor of Clinical Oncology and Faculty of Medicine at the University of Oslo. His research includes primary bone and soft-tissue cancers (sarcomas) and skeletal metastases from prostate cancer and breast cancer; targeted radionuclide therapy, such as the clinical development of Alpharadin, based on Radium-223; the tumor biology and prognostic impact of micro-metastases in bone-marrow aspirates on patients with primary bone cancer (osteosarcoma); external beam radiotherapy; and the radiotherapy of skeletal metastases and soft-tissue sarcomas. Dr. Bruland also is Co-⁠&#x2060;Founder of numerous biotech companies, including Algeta AS, Nordic Nanovector AS, Oncoinvent AS, and Nucligen/ARTBIO, Inc. He was elected a member of the Norwegian Academy of Science and Letters in 2008. Dr. Bruland holds a BSc, MD, and PhD from the University of Oslo, Norway.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Roy H. Larsen, PhD"
                        designation="ARTBIO Co-&#x2060;founder"
                        imgRef={royLarsenImg}
                        description="<p>Dr. Roy H. Larsen is the Co-Founder of ARTBIO and he works as a consultant through Sciencons AS. Dr. Larsen is an experienced inventor with a track record of innovation in radiopharmaceuticals. He founded Algeta ASA in 1997 to develop the first and only therapeutic containing an alpha emitter which became Xofigo. He served in different roles as the initial Managing Director, Chief Scientific Officer, while also serving on the Board of Directors. Algeta was acquired by Bayer in 2014. Dr. Larsen is also one of the founders of Nordic Nanovector ASA (2009), and Oncoinvent AS (2010). Roy H. Larsen was chairman of the board in Nordic Nanovector from 2009 to 2014, and a member of the Board until 2016. Dr. Larsen is currently Chairman of Oncoinvent. Dr. Larsen holds a PhD in radiopharmaceutical chemistry from University of Oslo, Norway, and had postdoctoral experience at Duke University, USA.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Oliver Sartor, MD"
                        designation="Advisor"
                        imgRef={oliverImg}
                        description="<p>Professor Oliver Sartor is a medical oncologist and translational researcher with a special focus on prostate cancer over the past 33 years. He completed his fellowship in Medical Oncology in 1989 at the National Cancer Institute, Bethesda. His productivity in research is documented by his scholarly output in terms of publications and presentations. He has over 500 peer review publications and has led multiple national and international advanced prostate cancer trials including those pivotal for FDA approval with samarium-153 EDTMP, cabazitaxel, radium-223 and PSMA-617 Lu-177. In addition, he has been the Oncology Chair of the NRG GU Committee and helping to co-PI trials such as RTOG 0521 and NRG-009. His translational focus on prostate cancer is devoted to improving the care of prostate cancer patients and a variety of translational efforts have been devoted to ensuring that the mechanistic and scientific understanding of prostate cancer provide the underpinnings for the next generation of clinical trials. Prof. Sartor has received the Tulane Medical Alumni Association Outstanding Alumnus Award and the Tulane, Deming Department of Medicine Award for Research (Hematology/Oncology). He is currently at Mayo Clinic in Rochester, MN (USA) where he continues to focus his efforts on the care of prostate cancer patients and clinical trials.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Richard P. Baum, MD, PhD"
                        designation="Advisor"
                        imgRef={richardImg}
                        description="<p>Richard P. Baum is the President of the International Centers for Precision Oncology (ICPO) Academy, a Founding Board Member of ICPO, and Chair of the ICPO Scientific Committee. He serves as a consultant at the Advanced Center for Radiomolecular Precision Oncology (RPO) at Curanosticum Wiesbaden-Frankfurt, Germany. Until the end of 2019, he was Chairman of the Center for Molecular Radiotherapy and Molecular Imaging at Zentralklinik Bad Berka for 22 years. Professor Baum is a clinician-scientist who has been involved in the use of radiolabeled monoclonal antibodies for imaging and therapy since the 1980s. He played a pivotal role in the introduction of Peptide Receptor Radionuclide Therapy (PRRT) for Neuroendocrine Tumors (NET) starting in 1997 when he performed the first PRRT in Germany at Frankfurt University Medical Center, where he became a Professor of Nuclear Medicine in 1989. Over the past 25 years, he and his team have treated over 2000 NET patients from around the world with more than 7000 PRRT applications. In 2013, he administered the world's first 177Lu-PSMA radioligand therapy (PSMA) and later pioneered the use of a combination of 177Lu and 255Ac-PSMA (TANDEM-PRLT). He has extensive clinical experience with over 3,000 radioligand treatments for patients with metastatic prostate cancer. In addition to his clinical research, he has conducted studies on genetic profiling of tumors, cancer screening by combining liquid biopsies and PET/CT, the development and evaluation of metabolic and molecular treatment response criteria, clinical research on new software tools for automated image analysis, as well as molecular radiation treatment planning and dosimetry before and after radioligand therapy.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Dennis Benjamin, MD, PhD"
                        designation="Advisor"
                        imgRef={dennisImg}
                        description="<p>Dr. Dennis Benjamin is a scientist with over 25 years’ experience in drug discovery with a particular focus on targeted therapies. He was most recently SVP of Research at Seagen where he was a key developer of the company’s ADC technology and clinical pipeline. Over his career he has led teams that have discovered 25 biologics and small molecules that entered clinical trials and contributed to 4 drug approvals, including ADCETRIS and PADCEV. Dr. Benjamin earned his B.S. in chemistry at M.I.T., a PhD in medicinal chemistry at U.C.S.F. and was a Hitchings-Elion Postdoctoral fellow at the University of Oxford.</p>"
                      />
                    </Col>
                  </Row>
                </TabPanel>

                <TabPanel>
                  <Row>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Ted W. Love, MD "
                        designation="Chairman"
                        imgRef={tedImg}
                        description="<p>Ted W. Love, MD is the Chair of BIO and member of the Board of Directors of Seagen and Royalty. He was CEO of Global Blood Therapeutics from June 2014 until its acquisition by Pfizer in October 2022. He served as Executive Vice President and Head of Research and Development for Onyx Pharmaceticals from January 2010–2014; in January 2011, he assumed responsibility for technical operations. Prior to joining Onyx, Ted was President, Chief Executive Officer and Chairman of the Board of Directors at Nuvelo, which he joined in 2001. Ted joined Nuvelo from Theravance Inc., where he served as Senior Vice President of Development from February 1998 to January 2001. Earlier in his career, Ted spent six years at Genentech, Inc., where he held a number of senior management positions in medical affairs and product development. As Vice President of Product Development and Regulatory Affairs at Genentech, he oversaw all drugs in development, including Herceptin, Rituxan, and TNKase. He also served as Chairman of Genentech’s Product Development Committee. Ted earned his BA in molecular biology from Haverford College and his MD from Yale Medical School. He completed his residency and fellowship training in internal medicine and cardiology at Harvard Medical School and Massachusetts General Hospital, where he later served on the faculty in the department of cardiology. Ted currently serves on the board of directors of Seagen, Royalty Pharma, and Global Blood Therapeutics. He is a member of the 2002 Class of Henry Crown Fellows and the Aspen Global Leadership Network at the Aspen Institute.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Roy H. Larsen, PhD"
                        designation="ARTBIO Co-founder, Sciencons"
                        imgRef={royLarsenImg}
                        description="<p>Dr. Roy H. Larsen is the Co-Founder of ARTBIO and he works as a consultant through Sciencons AS. Dr. Larsen is an experienced inventor with a track record of innovation in radiopharmaceuticals. He founded Algeta ASA in 1997 to develop the first and only therapeutic containing an alpha emitter which became Xofigo. He served in different roles as the initial Managing Director, Chief Scientific Officer, while also serving on the Board of Directors. Algeta was acquired by Bayer in 2014. Dr. Larsen is also one of the founders of Nordic Nanovector ASA (2009), and Oncoinvent AS (2010). Roy H. Larsen was chairman of the board in Nordic Nanovector from 2009 to 2014, and a member of the Board until 2016. Dr. Larsen is currently Chairman of Oncoinvent. Dr. Larsen holds a PhD in radiopharmaceutical chemistry from University of Oslo, Norway, and had postdoctoral experience at Duke University, USA.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Jeffrey Tong, PhD"
                        designation="Partner, Third Rock Ventures"
                        imgRef={jeffreyTongImg}
                        description="<p>Jeffrey Tong, Ph.D., is a Partner at Third Rock Ventures and has more than 20 years of experience in building innovative biotechnology companies. Prior to Third Rock, he was Executive Chairman of Delinia (acquired by Celgene), President and CEO of Nora Therapeutics, a member of the founding executive team of Infinity Pharmaceuticals, and started his career at McKinsey & Company. In addition to the ARTBIO board, Dr. Tong currently serves on the boards of Maze Therapeutics, Asher Bio, Rapport Therapeutics, and Septerna. Dr. Tong received his A.B., A.M., M.M.S., and Ph.D. from Harvard.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Ranjeev (Ronny) Krishana"
                        designation=""
                        imgRef={ranjeevKrishanaImg}
                        description="<p>Ranjeev (Ronny) Krishana serves as a member of our Board of Directors. Mr. Krishana has worked at Baker Bros. Advisors LP since 2011 and currently serves as Managing Director. Prior to joining Baker Bros., Mr. Krishana held a series of commercial, strategy, and business development leadership roles for Pfizer, Inc.’s pharmaceutical business across a variety of international regions and markets, including Asia, Europe, and Latin America. Mr. Krishana was at Pfizer from 2003 to 2007 and from 2008 to 2011. Mr. Krishana began his career as a strategy consultant at Accenture plc.  Mr. Krishana currently serves on the boards of BeiGene (Lead Independent Director) and Sironax. Mr. Krishana holds a B.A. in Economics and Political Science from Brown University, and a Masters of Public Policy from Harvard University.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Susanne Schaffert, PhD"
                        designation="Independent"
                        imgRef={susannelImg}
                        description="<p>Susanne brings broad experience across clinical development, marketing and sales, finance, and commercialization in the global pharmaceutical and biotechnology industries, with a focus on oncology, immuno-oncology, cell therapy, and radioligand therapies. She spent 25 years at Novartis, most recently as President of Novartis Oncology and member of the Executive Committee where she oversaw one of the biggest portfolios in oncology. Amongst other achievements, she was driving the launch of the two first-ever radioligand therapies in neuroendocrine tumors and prostate cancer. Before that, she served in various general management roles, including as Novartis’ Oncology Head of Europe as well as President and Chair of Accelerated Advanced Applications (AAA). She holds a PhD with honors in organic chemistry and an MSc in chemistry, both from the University of Erlangen (Germany).</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Alex Pasteur, PhD"
                        designation="Partner, F-Prime Capital"
                        imgRef={alexImg}
                        description="<p>Alex is a Partner with F-Prime Capital, based in London. He has worked in healthcare investing since 2001. Alex focuses on investments in therapeutics and healthcare IT. His portfolio includes Acacia Pharma (acquired by Eagle Pharmaceuticals, Inc.), Adaptimmune Therapeutics (NASDAQ: ADAP), ARTBIO Inc., Arvelle Therapeutics GmbH (acquired by Angelini Pharma Spa), Castor (Ciwit BV), Genomics PLC, Nodthera Ltd., Orchard Therapeutics (NASDAQ: ORTX), Oviva AG, Owkin Inc., Peptone Ltd., Pulmocide Ltd., and Shift Bioscience Ltd. Prior to joining F-Prime in 2012, Alex worked at MVM Life Science Partners LLP in the USA and Europe. Alex earned an MA in natural sciences from Cambridge University and a PhD in chemistry from Cambridge University.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Otello Stampacchia, PhD"
                        designation="Managing Director and Founder, Omega Funds"
                        imgRef={otelloImg}
                        description={`<p>Otello founded Omega Funds in 2004 and leads the firm’s investor relations and strategic initiatives. He is a member of the firm’s Investment Committee and also is heavily involved in a number of Omega’s therapeutic areas of interest, particularly in oncology, rare diseases, and inflammatory disorders.</p>

                        <p>Previously, Otello was in charge of life sciences direct investments and diligence for healthcare venture fund of fund investments at AlpInvest Partners, one of the largest private equity asset managers worldwide. Before AlpInvest, he was the Portfolio Manager of the Lombard Odier Immunology Fund, a $3 billion listed investment vehicle in Geneva, Switzerland, investing in public and private healthcare companies worldwide. Previously, Otello was a member of the HealthCare Corporate Finance and M&A team at Goldman Sachs (London and New York offices). Before Goldman, he helped co-found the healthcare investment activities at Index Securities (now Index Ventures). Otello has a PhD in molecular biology from the University of Geneva, holds a European doctorate in biotechnology from the EU, and obtained an MSc in genetics from the University of Pavia.</p>
                        `}
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Emanuele Ostuni, PhD"
                        designation="Founding CEO, ARTBIO"
                        imgRef={emanueleImg}
                        description="<p>Emanuele is the Founding CEO of ARTBIO. He worked closely with Roy Larsen and ARTBIO’s investors to build the company and its business model. Previously, he was Head of Europe for Cell and Gene Therapies at Novartis Oncology, where he oversaw all commercialization aspects for Kymriah in Europe, the first FDA-approved CAR-T cell therapy. Emanuele was with Novartis since 2012 in various executive roles in Sandoz with different geographic responsibilities: Global, Central and Eastern Europe, and Romania. Previously he held positions at Nano Terra, McKinsey & Co., and Surface Logix. Emanuele was the Co-⁠&#x2060;Founder of Enumeral Biomedical and served on its board of directors through its public listing in 2014. He served on the Scientific Advisory Board (SAB) of Anixa Biosciences and currently serves on the SAB of Elicio Therapeutics. Emanuele earned a PhD in physical chemistry from Harvard University and bachelor's and master's degrees in chemistry from Georgetown University.</p>

                        <p>Artist or Artisan?</p>

                        <p>Artisan. Emanuele is passionate about skiing, gardening, and growing orchids.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Anders Tuv"
                        designation="Partner, Radforsk (Observer)"
                        imgRef={andrewImg}
                        description="<p>Anders Tuv is Chief Investment Officer of life science investment company Radforsk, which is focused on immunotherapies and precision medicines. He is an experienced investment and business development professional in the life science industry. His roles and responsibilities cover management positions, strategy and business development, research collaborations, licensing deals, M&A, and IPOs. Anders also holds several chairman and non-executive director positions.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Martin Taylor, PhD"
                        designation="Senior Associate, F-Prime (Observer)"
                        imgRef={martinImg}
                        description="<p>Martin is a Senior Associate in F-Prime Capital’s London office, where he focuses on early-stage investing and company building efforts in biotechnology platform companies. He also served as a member of ARTBIO’s Founding Operational Leadership Team during the company’s incubation phase from 2021-22. Prior to joining F-Prime in 2019, he spent a decade in academia working in the field of DNA replication and repair, most recently as a Wellcome Trust Postdoctoral Fellow at the MRC Laboratory of Molecular Biology, where he studied molecular mechanisms of DNA replication. Martin completed his PhD at the Francis Crick Institute, investigating the biochemistry of DNA repair by homologous recombination. His research has been published in several original articles in journals including <i>Nature</i>, <i>Cell</i>, and <i>Molecular Cell</i>. Martin also holds BA and MSci degrees in biochemistry from the University of Cambridge.</p>"
                      />
                    </Col>
                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Francesco Draetta"
                        designation="Partner, Omega Funds (Observer)"
                        imgRef={francessoImg}
                        description={`<p>Francesco is a Partner at Omega Funds, where he focuses on early-stage investments and fundraising. Francesco currently serves on the Board of Directors of NRG Therapeutics, and as a Board Observer for LEXEO Therapeutics. He previously served as a Board Director for Chord Therapeutics (acquired by Merck KGaA) and as a Board Observer for Amunix Pharmaceuticals (acquired by Sanofi).</p>
                        <p>Prior to rejoining Omega in 2016, Francesco was part of the investment teams at Brookside Mezzanine Partners and at Commonfund Capital. He began his career at Omega Funds as an analyst.</p>
                        <p>Francesco is a CFA charterholder, a CAIA charterholder, and a Certified FRM. He graduated cum laude from the Isenberg School of Management at the University of Massachusetts Amherst with a BBA in finance and operations management.</p>
                        `}
                      />
                    </Col>

                    <Col xl={4} md={6}>
                      <TeamDetails
                        teamName="Vyas Ramanan, PhD"
                        designation="Venture Partner, Third Rock Ventures (Observer)"
                        imgRef={vyasImg}
                        description="<p>Vyas Ramanan, Ph.D., is a Venture Partner at Third Rock Ventures, where he has worked since 2016 on building exciting biopharmaceutical companies. As part of Third Rock, Vyas played key roles in the creation and R&D activities of Maze Therapeutics and Marea Therapeutics. In addition to ARTBIO, Vyas currently serves as a board observer for Marea Therapeutics. Vyas received his BS in Economics and BSE/MSE in Bioengineering from the University of Pennsylvania, and his Ph.D. from the Harvard-MIT Division of Health Sciences and Technology.</p>"
                      />
                    </Col>
                  </Row>
                </TabPanel>
              </ResponsiveTabs>
            </div>
          </div>
        </FluidContent>
      </Section>

      <Section id="investor">
        <FluidContent className="investors-block block-inner-py">
          <div className="centered-content">
            <div className="investors-block__content" ref={fadeToRefs}>
              <BlockTitle Title="Partners" />

              <Row className="row" style={{ paddingTop: "30px" }}>
                <Col lg={12} md={12} className="col">
                  <h3 className="sub-title">Discovery and Development</h3>
                </Col>
              </Row>

              <Row className="row">
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={alphaGenLogo}
                      alt="AlphaGen logo"
                      className="mw-280"
                      width={336}
                      height={60}
                      loading="lazy"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-right">
                    <img
                      src={parabilismedicines}
                      alt="Parabilis Medicines logo"
                      className="mw-250"
                      width={260}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-right">
                    <img
                      src={OsloLogo}
                      alt="Osla University Hospital logo"
                      className="mw-250"
                      width={336}
                      height={60}
                      loading="lazy"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-right">
                    <img
                      src={threeBLogo}
                      alt="3B Pharmaceuticals logo"
                      className="mw-250"
                      width={260}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="row">
                <Col lg={12} md={12} className="col">
                  <h3 className="sub-title">Manufacturing</h3>
                </Col>
              </Row>

              <Row className="row">
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-left">
                    <img
                      src={agileraLogo}
                      alt="Agilera logo"
                      className="mw-240"
                      width={260}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={alphaGenLogo}
                      alt="AlphaGen logo"
                      className="mw-280"
                      width={336}
                      height={60}
                      loading="lazy"
                    />
                  </div>
                </Col>
                {/* <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={eckertLogo}
                      alt="Eckert & Ziegler logo"
                      className="mw-250"
                      width={260}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col> */}
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-left">
                    <img
                      src={nucleusLogo}
                      alt="Nucleus Radiopharma logo"
                      className="mw-200"
                      width={200}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col>

                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={pharmaLogicLogo}
                      alt="PharmaLogic logo"
                      className="mw-250"
                      width={260}
                      height={86}
                      loading="lazy"
                    />
                  </div>
                </Col>

                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={spectronRxLogo}
                      alt="SpectronRx logo"
                      className="mw-280"
                      width={336}
                      height={60}
                      loading="lazy"
                    />
                  </div>
                </Col>

                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <img
                      src={thormedical}
                      alt="Thor Medical logo"
                      className="mw-280"
                      width={336}
                      height={60}
                      loading="lazy"
                    />
                  </div>
                </Col>
              </Row>

              <BlockTitle Title="Investors" />
              <Row className="row">
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <a
                      href="https://fprimecapital.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={fPrimeLogo}
                        alt="F-PRIME logo"
                        className="mw-240"
                        width={260}
                        height={86}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo">
                    <a
                      href="https://omegafunds.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={omegafundsLogo}
                        alt="Omega Funds logo"
                        className="mw-280"
                        width={336}
                        height={60}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </Col>
                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-right">
                    <a
                      href=" https://radforsk.no"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={radforskLogo}
                        alt="RADFORSK logo"
                        className="mw-250"
                        width={260}
                        height={86}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </Col>

                <Col lg={3} md={6} className="col">
                  <div className="partners-logo text-lg-right">
                    <a
                      href="https://www.thirdrockventures.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={thirdRockLogo}
                        alt="Third Rock Venture logo"
                        className="mw-280"
                        width={336}
                        height={60}
                        loading="lazy"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </FluidContent>
      </Section>

      <Section id="news">
        <FluidContent className="news-block bg-cover block-inner-py">
          <img
            src={newsBgDesktop}
            alt="News background shape"
            className="desktop-only"
            loading="lazy"
          />
          <img
            src={newsBgMobile}
            alt="News background shape"
            loading="lazy"
            className="mobile-only"
          />
          <div className="centered-content">
            <div className="news-block__content" ref={fadeToRefs}>
              <BlockTitle className="text-white" Title="News and Resources" />
              <span h4 className="h4 text-white">
                Learn about the latest ARTBIO news.
              </span>
            </div>
            <div className="news-article mt-36" ref={fadeToRefs}>
              <AllBlogPosts />
            </div>
          </div>
        </FluidContent>
      </Section>

      <Section id="careers">
        <FluidContent className="careers-block  block-inner-py">
          <div className="centered-content">
            <div className="careers-block__content text-white" ref={fadeToRefs}>
              <BlockTitle
                className="text-white gradient-border gradient-border--yellow"
                Title="Join our team"
              />
              <p>The journey is better together.</p>
              <p>
                Together, we approach challenges head-on and with an open mind.
                Join our team to help us usher in an entirely new standard of
                care for cancer patients.
              </p>
            </div>
            <div className="careers-list mt-30">
              <h3 className="text-white mt-30 mb-30" ref={fadeToRefs}>
                Join us if you are...
              </h3>
              <Row ref={fadeToRefs}>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    className="bradius-topleft-40"
                    cardBg={ListBg2}
                    cardBgAlt="bg-sky-blue shape"
                    cardTitle="passionate about making a difference for cancer patients"
                  />
                </Col>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    cardBg={ListBg1}
                    cardBgAlt="bg-orange shape"
                    cardTitle="a humble achiever"
                  />
                </Col>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    className="bradius-topright-40"
                    cardBg={ListBg5}
                    cardBgAlt="bg-pink shape"
                    cardTitle="a practical optimist"
                  />
                </Col>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    className="bradius-bottomleft-40"
                    cardBg={ListBg6}
                    cardBgAlt="bg-yellow shape"
                    cardTitle="a consummate learner and un-learner"
                  />
                </Col>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    cardBg={ListBg4}
                    cardBgAlt="bg-voilet shape"
                    cardTitle="driven by data and cherish transparency"
                  />
                </Col>
                <Col lg={4} md={6} className="mb-20">
                  <CardInfo
                    className="bradius-bottomright-40"
                    cardBg={ListBg3}
                    cardBgAlt="bg-orange shape"
                    cardTitle="a compassionate team player"
                  />
                </Col>
              </Row>
              <Row ref={fadeToRefs}>
                <Col lg={8} md={12}>
                  <h3 className="text-white mt-30 mb-30">
                    Applying to ARTBIO: Beware of Fraudulent Recruiting Ads and
                    Scams
                  </h3>
                  <p className="text-white">
                    ARTBIO and other biotech companies have recently noticed an
                    increase in email and social media scams targeting job
                    candidates in our industry. Please note ARTBIO will post any
                    legitimate job openings directly on our website (
                    <Link to="/" className="text-white underline">
                      www.artbio.com
                    </Link>
                    ) or through our recruitment partner, Lever. If you wish to
                    verify the legitimacy of a job opening, you may contact us
                    through{" "}
                    <a
                      href="mailto:info@artbio.com"
                      className="text-white underline"
                    >
                      info@artbio.com
                    </a>{" "}
                    and someone from our team will respond directly.
                  </p>
                </Col>
              </Row>
              <div className="open-positions" ref={fadeToRefs}>
                <h3 className="text-white mt-30 mb-30">Open positions</h3>
              </div>
              <div className="jobs-list" ref={fadeToRefs}>
                {openPositions.length === 0 && (
                  <p>
                    There are currently no open positions available, please
                    check back soon.
                  </p>
                )}
                <Row ref={fadeToRefs}>
                  {openPositions.slice(0, jobToShow).map((item) => {
                    return (
                      <Col xs={12}>
                        <CardJob
                          jobId={item.id}
                          jobLink={item.urls?.show}
                          jobDepartment={item.categories?.team}
                          jobTitle={item.text}
                          jobLocation={item.categories?.location}
                        />
                      </Col>
                    );
                  })}
                </Row>
                {openPositions.length > 0 &&
                  openPositions.length > jobToShow && (
                    // <button onClick={handleLoadMoreClick}>Load More +</button>
                    <div className="btn-wrap">
                      <Button
                        className="btn btn--alt text-white"
                        btnTitle="Load More"
                        btnIcon={PlusIcon}
                        onClick={handleLoadMoreClick}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </FluidContent>
      </Section>
    </HcpLayout>
  );
};

export default IndexPage;

export const Head = () => (
  <Seo
    title="ARTBIO"
    description="Learn about how ARTBIO is redefining cancer care through a new class of alpha radioligand therapies and building an ecosystem to maximize their potential."
  />
);
